import Vue from 'vue'
import Router from 'vue-router'
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'
import Home from "../components/Home";
import Cells from "../components/Cells";
import Docs from "../components/Docs";
import Download from "../components/Download";

Vue.use(vueFilterPrettyBytes);
Vue.use(Router);


const routes = [
    { path: "/", component: Home },
    { path: "/home", redirect: "/" },
    { path: "/cells/:id", component: Cells },
    { path: "/download", component: Download },
    { path: "/docs/:id", component: Docs}
];


const originalPush = Router.prototype.push
   Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}


const router = new Router({
    routes: routes,
});


window.router = router;

export default router