import {createURL} from "./utils";

const urls = {
    logo: createURL("/static/logo.jpg"),
    stats: createURL("/api/stats"),
    cells: createURL("/api/cells"),
    coord: createURL("/api/coord"),
    gene: createURL("/api/gene"),
    expr: createURL("/api/expr"),
    header: createURL("/api/thead"),
    marker: createURL("/api/marker"),
    list: createURL("/api/list"),
    download: createURL("/api/download"),
    static: createURL("/static/"),
    module: createURL("/api/module"),
    annotation: createURL("/api/annotation")
};

export default urls;

