<template>
  <div>
      <el-row>
          <el-col :span="3" :offset="6">
              <el-image
                  style="width: 80px; height: 80px;"
                  :src="logo"
                  fit="fit"
              />
          </el-col>
          <el-col :span="6" :offset="0">
              <h1>{{ name }}</h1>
          </el-col>
      </el-row>

      <el-row>
          <el-col :span="18" :offset="3">
              <div>
                  Color by:
                  <el-radio-group v-model="radio.value" @change="getStats">
                      <el-radio :key="val" v-for="val in radio.options" :label="val">{{capitalize(val)}}</el-radio>
                  </el-radio-group>
                  <el-divider />
                  <v-chart :options="stats" style="height: 600px; width: 100%" :theme="theme" />
              </div>
          </el-col>
      </el-row>

  </div>
</template>

<script>
    import urls from '../utils/const';
    import {StatsBar} from "../utils/plot";

    import 'echarts/theme/shine';

    export default {
        data() {

            return {
                name: "Pancratic Cancer",
                logo: urls.logo,
                theme: "shine",
                stats: null,
                radio: {
                    'value': "",
                    'options': []
                },
            }
        },
        methods: {
            capitalize: function(s) {
                s = s.charAt(0).toUpperCase() + s.slice(1);
                s = s.replace("_", " ")
                return(s)
            },
            initStats: function() {
                const self = this;
                this.axios.get(urls.stats, {params: {}}).then(response => {
                    self.radio.options = response.data;
                    self.radio.value = response.data[0];
                    self.getStats()
                }).catch(error => {
                    window.console.log(error);
                    self.$notify({
                        showClose: true,
                        type: 'error',
                        title: `Error Status: ${error.response.status}`,
                        message: error.response.data["Message"]
                    });
                });
            },
            getStats: function() {
                const self = this;
    
                this.axios.get(urls.stats, {
                    params: {src: this.radio.value}
                }).then(response => {
                    self.stats = StatsBar(response.data, `${self.radio.value}_stats`);
                }).catch(error => {
                    window.console.log(error);
                    self.$notify({
                        showClose: true,
                        type: 'error',
                        title: `Error Status: ${error.response.status}`,
                        message: error.response.data["Message"]
                    });
                });
            }
        },
        mounted() {
            this.initStats();
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }
</style>
