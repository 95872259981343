<template>
  <div id="app">
    <el-container :height="windowHeight">
      <el-aside
              width="210px"
              style="overflow-x: hidden; height: 100%; background-color: #545c64">
        <el-row>
          <p style="color:white; font-size: 20px">
            <el-image style="width: 40px; height: 40px;" :src="logo" fit="fit" />
            {{ name }}
          </p>
        </el-row>

        <el-menu
                :default-active="this.$router.currentRoute.name"
                background-color="#545c64"
                text-color="#fff"
                active-text-color="#ffd04b"
                style="padding-left: 0px"
                router
        >
          <el-menu-item index="/home" style="padding-left:10px">
              <i class="el-icon-s-home" />
              <span slot="title">Home</span>
          </el-menu-item>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-message" />
              <span slot="title">Exploration</span>
            </template>
            <el-menu-item-group style="padding-left:0px">
              <template slot="title" style="padding-left:10px">Malignant cells</template>
              <el-menu-item v-for="(num, cell) in this.cells" :index="cell" :route="'/cells/' + cell" :key="cell" style="padding-left:10px">
                <span slot="title">{{cell}}(n={{num}})</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="download" route="/download" style="padding-left:10px">
              <i class="el-icon-download" />
              <span slot="title">Download</span>
          </el-menu-item>
          
          <el-submenu index="4">
            <template slot="title">
              <i class="el-icon-menu" />
              <span slot="title">Docs</span>
            </template>
            <el-menu-item index="/docs/about" style="padding-left:10px">
              <i class="el-icon-user" />
              <span slot="title">About</span>
            </el-menu-item>
            <el-menu-item index="/docs/api" style="padding-left:10px">
              <i class="el-icon-bell" />
              <span slot="title">API</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>

      <el-container style="height: 100%;">
        <el-main id="main">
          <router-view />

          <el-backtop target="#main" :visibility-height="100" />
        </el-main>

        <el-footer>
          <p>
            Copyright@2019
          </p>
        </el-footer>

      </el-container>
    </el-container>
    
  </div>
</template>

<script>
    import urls from './utils/const'
    import {isMobile} from "./utils/utils";

    export default {
        data() {
            return {
                name: "Pancretic cancer",
                windowHeight: "600px",
                isMobile: isMobile(),
                logo: urls.logo,
                cells: {"Acinar": 500, "Ductal 2": 500,}
            }
        },
        mounted() {
            this.height = window.innerHeight;

            if (this.isMobile) {
                this.$notify({
                    title: 'Warning',
                    message: 'Mobile device probably may not be able to process the data on this site, Please use desktop device to better experience',
                    type: 'warning'
                });
            }

            const self = this;

            this.axios.get(urls.stats, {
                params: {src: "cell"}
            }).then(response => {
                self.cells = response.data;
            }).catch(error => {
                window.console.log(error);
                self.$notify({
                    showClose: true,
                    type: 'error',
                    title: `Error Status: ${error.response.status}`,
                    message: error.response.data["Message"]
                });
            });
        }
    };
</script>

<style>
  html, body {
    height: 100%;
  }
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    display: flex;
    height: 100%;
    overflow-y: scroll;
  }

  .el-footer {
    font-size: 20px;
    text-align: right;
    align-items: center;
    background-color: #F2F6FC;
    line-height: 60px;
  }

  * { margin:0; padding:0; }
</style>
