<template>
    <div>
        <h1>Download</h1>
        <el-divider />

        <el-collapse v-model="activeName" accordion>
            <el-collapse-item :title="file.name" :name="idx" v-for="(file, idx) in data" :key="file.name">
                <div><el-link type="primary" @click="download(file.name)">下载 {{file.name}}</el-link></div>
                <div class="text item" v-html="converter.makeHtml(file.description.join('\n'))" />
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
    import urls from '../utils/const';
    import showdown from 'showdown'

    export default {
        name: "Download",
        data() {
            return {
                data: [],
                activeName: "1",
                converter: new showdown.Converter(),
            }
        },
        mounted() {
            const self = this;

            this.axios.get(urls.list)
                .then(response => {
                    self.data = response.data;
                }).catch(error => {
                    window.console.log(error);
                    self.$notify({
                        showClose: true,
                        type: 'error',
                        title: `Error Status: ${error.response.status}`,
                        message: error.response.data["Message"]
                    });
                })
        },
        methods: {
            download (href) {
                window.open(`${urls.download}/?name=${href}`)
            }
        }
    }
</script>

<style>
    li {
        margin-left: 10px;
    }

    li li {
        margin-left: 20px;
    }

    ol {list-style-position: inside;}

    strong {
        color: #F56C6C;
    }

    code {
        background-color: #e4e4e4;
    }
</style>