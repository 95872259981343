<template>
    <el-row>
        <el-row ref="header">
            <h3>{{ this.cell }}</h3>
        </el-row>
        <el-divider />

        <el-row>
            <el-tabs v-if="this.cell !== ''" type="border-card">
                <el-tab-pane label="Cell & Expression">
                    <el-row>
                        <el-col :span="6">
                            <span>Reduction by: </span>
                            <el-radio-group v-model="reduction.value" @change="handleSwitchChange('coord')" size="small">
                                <el-radio-button v-for="v in reduction.options" :key="v" :label="v" />
                            </el-radio-group>

                            <el-divider />
                            <span>Color by: </span>
                            <el-radio-group v-model="scatter.colorby.value" @change="handleSwitchChange('coord')" size="small">
                                <el-radio-button v-for="v in scatter.colorby.options" :key="v" :label="v" />
                            </el-radio-group>

                            <el-divider />
                        </el-col>
                        <el-col :span="8" v-loading="scatter.loading">
                            <v-chart init-options="{}" :options="scatter.options" style="width: 600px; height: 600px" :theme="theme"/>
                        </el-col>
                    </el-row>
                    <el-divider />
                    <el-row>
                        <el-col :span="6">
                            Please input gene symbol or Ensembl ID:
                            <el-autocomplete
                                    v-model="expr.gene.value"
                                    class="inline-input"
                                    :fetch-suggestions="querySearch"
                                    placeholder="A1BG or ENSG00000121410"
                                    :trigger-on-focus="false"
                                    :clearable="true"
                                    style="width: 100%"
                                    @select="getExprByGene"
                                    @clear="clearInput"
                            />
                            <el-divider />
                            <span>Expression value: </span>

                            <el-radio-group v-model="expr.log" :disabled="this.expr.data === null" @change="changeLog">
                                <el-radio :label="0">Counts</el-radio>
                                <el-radio :label="2">log2(counts + 1) </el-radio>
                                <el-radio :label="10">log10(counts + 1)</el-radio>
                            </el-radio-group>

                        </el-col>
                        <el-col :span="8" v-loading="expr.loading">
                            <v-chart :options="expr.options" style="width: 600px; height: 600px" v-if="this.expr.gene.value.length > 0" :theme="theme" />
                        </el-col>
                    </el-row>

                </el-tab-pane>
                <el-tab-pane label="Markers">

                    <el-row>
                        <span>Markers type: </span>
                        <el-radio-group v-model="table.type" @change="handleThresholdChange">
                            <el-radio-button v-for="val in table.options" :key="val" :label="val">{{ val }}</el-radio-button>
                        </el-radio-group>

                        <el-divider />
                        <h3>Adjust the threshold to get different markers</h3>
                        <el-divider />
                        <el-row>
                            <el-col :span="4">
                                <span class="demonstration" style="text-align: right">Makers with pct.1 &ge;</span>
                            </el-col>
                            <el-col :span="8">
                                <el-slider v-model="table.form.pct1" :min="0" :max="1" :step="0.01" show-input style="width: 80%" @change="handleThresholdChange"/>
                            </el-col>
                            <el-col :span="4">
                                <span class="demonstration" style="text-align: right">Makers with pct.2 &ge;</span>
                            </el-col>
                            <el-col :span="8">
                                <el-slider v-model="table.form.pct2" :min="0" :max="1" :step="0.01" show-input style="width: 80%" @change="handleThresholdChange"/>
                            </el-col>

                        </el-row>

                        <el-row>
                            <el-col :span="4">
                                <span class="demonstration" style="text-align: right">Makers with p value &le; </span>
                            </el-col>
                            <el-col :span="8">
                                <el-slider v-model="table.form.p_val" :min="0" :max="1" :step="0.01" show-input style="width: 80%" @change="handleThresholdChange"/>
                            </el-col>
                            <el-col :span="4">
                                <span class="demonstration" style="text-align: right">Makers with adjust p value &le; </span>
                            </el-col>
                            <el-col :span="8">
                                <el-slider v-model="table.form.p_val_adj" :min="0" :max="1" :step="0.01" show-input style="width: 80%" @change="handleThresholdChange"/>
                            </el-col>

                        </el-row>

                        <el-row>
                            <el-col :span="4">
                                <span class="demonstration" style="text-align: right">Makers with abs(avg. logFC) &ge;</span>
                            </el-col>
                            <el-col :span="8">
                                <el-slider v-model="table.form.avg_log_fc" :min="0" :max="1" :step="0.01" show-input style="width: 80%" @change="handleThresholdChange"/>
                            </el-col>

                            <el-col :span="4">
                                <el-select
                                        v-model="table.form.ident"
                                        placeholder="Makers in group"
                                        style="width: 100%"
                                        :disabled="table.form.idents.length === 0"
                                        @change="getMarkers"
                                >
                                    <el-option
                                            v-for="item in table.form.idents"
                                            :key="item"
                                            :label="item"
                                            :value="item">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="4">
                                <el-autocomplete
                                        v-model="table.form.gene.value"
                                        class="inline-input"
                                        :fetch-suggestions="querySearch"
                                        placeholder="A1BG or ENSG00000121410"
                                        :trigger-on-focus="false"
                                        :clearable="true"
                                        style="width: 100%"
                                        @select="getMarkersByGene"
                                        @clear="clearGeneInputOfMarkers"
                                />
                            </el-col>
                            <el-col :span="4">
                                <el-button round>
                                    <download-csv :data = "table.data" :name="`${this.cell}_${this.cell.replace(' ', '_')}_${table.type}_markers.csv`" >Download</download-csv>
                                </el-button>
                            </el-col>
                        </el-row>

                        <el-divider />

                        <el-table
                                :data="table.data"
                                style="width: 100%"
                                :default-sort = "{prop: table.defaultSort.prop, order: table.defaultSort.order}"
                                @sort-change="handleSortChange"
                                v-loading="table.loading"
                                :stripe="true"
                                :border="true"
                        >
                            <el-table-column v-for="data in table.header" :key="data.label"
                                             :prop="data.prop"
                                             :label="data.label"
                                             :sortable="data.sortable"
                            >
                                <template  slot-scope="scope">
                                    <el-popover v-if="data.prop === 'p_val'"  trigger="hover" placement="top">
                                        {{ scope.row.p_val }}
                                        <div slot="reference" class="name-wrapper">
                                            <el-tag size="medium">{{ formatScientific(scope.row.p_val, '0.00e+0') }}</el-tag>
                                        </div>
                                    </el-popover>
                                    <el-popover v-else-if="data.prop === 'p_val_adj'"  trigger="hover" placement="top">
                                        {{ scope.row.p_val_adj }}
                                        <div slot="reference" class="name-wrapper">
                                            <el-tag size="medium">{{ formatScientific(scope.row.p_val_adj, '0.00e+0') }}</el-tag>
                                        </div>
                                    </el-popover>
                                    <el-popover v-else-if="data.prop === 'pct1'"  trigger="hover" placement="top">
                                        {{ scope.row.pct1 }}
                                        <div slot="reference" class="name-wrapper">
                                            <el-tag size="medium">{{ formatScientific(scope.row.pct1, '0.00%') }}</el-tag>
                                        </div>
                                    </el-popover>
                                    <el-popover v-else-if="data.prop === 'pct2'"  trigger="hover" placement="top">
                                        {{ scope.row.pct2 }}
                                        <div slot="reference" class="name-wrapper">
                                            <el-tag size="medium">{{ formatScientific(scope.row.pct2, '0.00%') }}</el-tag>
                                        </div>
                                    </el-popover>
                                    <el-popover v-else-if="data.prop === 'avg_log_fc'"  trigger="hover" placement="top">
                                        {{ scope.row.avg_log_fc }}
                                        <div slot="reference" class="name-wrapper">
                                            <el-tag size="medium">{{ formatScientific(scope.row.avg_log_fc, '0.00') }}</el-tag>
                                        </div>
                                    </el-popover>
                                    <span v-else>{{ scope.row[data.prop] }}</span>
                                </template>
                            </el-table-column>

                        </el-table>

                        <el-pagination
                                background
                                :hide-on-single-page="true"
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="table.start"
                                :page-sizes="[10, 50, 100, 200]"
                                :page-size="table.length"
                                layout="sizes, prev, pager, next"
                                :total="table.total">
                        </el-pagination>
                    </el-row>
                </el-tab-pane>
                
            </el-tabs>
        </el-row>
    </el-row>
</template>

<script>
    import numeral from 'numeral';
    import urls from '../utils/const';
 
    // import {FormatPanelOptions} from "../utils/celltree";
    import {ScatterPlot, ScatterPlotExpr} from "../utils/plot";

    import 'echarts/theme/shine';

    export default {
        name: "Cells",
        methods: {
            calculate(string) {
                let temp = string.split("/");
                return numeral((temp[0] / temp[1] * 100)/100).format('0.00%');
            },

            formatScientific(num, format) {
                return numeral(num).format(format);
            },

            clearGeneString(item) {
                return item.ensembl !== "" && item.ensembl !== null ? item.ensembl : item.symbol;
            },

            handleSwitchChange(kind) {
                window.console.log(kind);
                this.scatter.loading = true;
                const self = this;

                this.axios.get(urls.coord, {
                    params: {
                        "cell": self.cell,
                        "coord": self.reduction.value,
                        "group": self.scatter.colorby.value
                    }
                }).then(response => {
                    self.scatter.options = ScatterPlot(response.data);
                }).catch(error => {
                    window.console.log(error);
                    self.$notify({
                        showClose: true,
                        type: 'error',
                        title: `Error Status: ${error.response.status}`,
                        message: error.response.data["Message"]
                    });
                }).finally(function () {
                    self.scatter.loading = false;
                });

                if (kind === "coord" &&  self.expr.gene.value.length > 0) {
                    self.getExpr()
                }
            },

            // handle the switch change
            handleChange: function() {
               
                if (this.reduction.value !== "") {
                    this.handleSwitchChange("");
                    // if gene was set, change expression plot too
                    if (this.expr.gene.value !== "" ) {
                        this.getExpr()
                    }
                }
            
                this.getMarkers();
            },

            // handleThresholdChange
            handleThresholdChange: function() {
                this.table.start = 1;
                this.getMarkers()
            },


            clearInput: function() {
                this.expr.gene.value = "";
                this.expr.data = null;
            },

            // handle the auto complete issue
            querySearch: function(queryString, cb) {
                if(queryString.length > 1) {
                    const self = this;
                    this.axios.get(urls.gene, {params: {gene: queryString}}).then(response => {
                        let results = [];
                        response.data.forEach(function(element) {
                            results.push({
                                "value": `${element['Name']} | ${element['Ensembl']}`,
                                "symbol": element['Name'],
                                "ensembl": element['Ensembl']
                            })
                        });
                        // 调用 callback 返回建议列表的数据
                        cb(results);
                    }).catch(error => {
                        window.console.log(error);
                        self.$notify({
                            showClose: true,
                            type: 'error',
                            title: `Error Status: ${error.response.status}`,
                            message: error.response.data["Message"]
                        });
                    });
                }
            },

            getExprByGene: function(item) {
                this.expr.gene = item;
                this.getExpr();
            },

            // handle the expression query issue
            getExpr: function() {
                this.expr.loading = true;
                const self = this;
                this.axios.get(urls.expr, {
                    params: {
                        cell: self.cell,
                        coord: self.reduction.value,
                        gene: self.clearGeneString(self.expr.gene)
                    }
                }).then(response => {
                    self.expr.data = JSON.parse(JSON.stringify(response.data));
                    self.expr.options = ScatterPlotExpr(response.data, self.expr.log);
                }).catch(error => {
                    window.console.log(error);
                    self.$notify({
                        showClose: true,
                        type: 'error',
                        title: `Error Status: ${error.response.status}`,
                        message: error.response.data["Message"]
                    });
                }).finally(function () {
                    self.expr.loading = false;
                })
            },

            // switch log
            changeLog: function() {
                this.expr.loading = true;
                this.expr.options = ScatterPlotExpr(this.expr.data, this.expr.log);
                this.expr.loading = false;
            },

            // markers pagination related function
            handleCurrentChange(val) {
                this.table.start = val;
                this.getMarkers();
            },
            // markers pagination related
            handleSizeChange(val) {
                this.table.length = val;
                this.getMarkers();
            },

            // marker table related
            handleSortChange(column) {
                this.table.sort = column.prop;
                this.table.order = column.order;
                this.getMarkers();
            },

            // ajax of markers
            getMarkers() {
                this.table.loading = true;
                const self = this;
                this.axios.get(urls.marker, {
                    params: {
                        cell: self.cell,
                        marker: self.table.type,
                        start: self.table.start,
                        length: self.table.length,
                        orderBy: self.table.sort,
                        order: self.table.sort !== "" ? self.table.order : "",
                        pct1: self.table.form.pct1,
                        pct2: self.table.form.pct2,
                        pval: self.table.form.pval,
                        padj: self.table.form.p_val_adj,
                        logfc: self.table.form.avg_log_fc,
                        ident: self.table.form.ident,
                        gene: self.clearGeneString(self.table.form.gene)
                    }
                }).then(response => {
                    let data = response.data;
                    self.table.data = data.data;
                    self.table.total = data.total;
                    self.table.length = data.length;
                    self.table.start = data.start;
                    self.table.form.idents = data.groups.sort();
                }).catch(error => {
                    window.console.log(error.response);
                    self.$notify({
                        showClose: true,
                        type: 'error',
                        title: `Error Status: ${error.response.status}`,
                        message: error.response.data["Message"]
                    });
                }).finally(function () {
                    self.table.loading = false;
                })
            },

            getMarkersByGene(item) {
                this.table.form.gene = item;
                this.getMarkers()
            },

            clearGeneInputOfMarkers() {
                this.table.form.gene = "";
                this.getMarkers()
            },

            setSupporttedCoord() {
                const self = this;
                // set supported coord type
                this.axios.get(
                    urls.coord, 
                    {params: {cell: this.cell}}
                ).then(response => {
                    self.reduction.options = response.data;

                    for (let value of response.data) {
                        if (self.reduction.value === "") {
                            self.reduction.value = value
                        } else if (value.toLowerCase() === "umap" ) {
                            self.reduction.value = value
                        }
                    }

                    this.handleChange()
                }).catch(error => {
                    window.console.log(error);
                    self.$notify({
                        showClose: true,
                        type: 'error',
                        title: `Error Status: ${error.response.status}`,
                        message: error.response.data["Message"]
                    });
                });


                // set supported mnarker type
                this.axios.get(
                    urls.marker, 
                    {params: {cell: this.cell}}
                ).then(response => {
                    self.table.options = response.data;
                }).catch(error => {
                    window.console.log(error);
                    self.$notify({
                        showClose: true,
                        type: 'error',
                        title: `Error Status: ${error.response.status}`,
                        message: error.response.data["Message"]
                    });
                });

            },

            init() {
                this.height = window.innerHeight;
                this.cell = this.$route.params.id
                
                // set tale headers
                const self = this;
                this.axios.get(urls.header).then(response => {
                    let header = response.data;

                    self.table.header = header["markers"];
                    for (let i = 0; i < self.table.header.length; i ++ ) {
                        if (self.table.header[i]["default_order"]) {
                            self.table.defaultSort.prop = self.table.header[i].prop;
                            self.table.sort = self.table.header[i].prop;
                            break
                        }
                    }
                }).catch(error => {
                    window.console.log(error);
                    self.$notify({
                        showClose: true,
                        type: 'error',
                        title: `Error Status: ${error.response.status}`,
                        message: error.response.data["Message"]
                    });
                });

                this.setSupporttedCoord();
            }
        },
        mounted() {this.init()},
        watch: {  // watch changes of id
            '$route.params.id': function () {this.init()}
        },
   
        data() {
            return {
                cell: "",
                height: "600px", theme: "shine", 
                reduction: {"value": "", "options": []}, 
                options: null,
                scatter: {
                    options: null, loading: false, 
                    colorby: {value: "Cluster", options: ["Cluster", "Subtype", "Malignant"]},
                },
                expr: {
                    gene: {value: "", ensembl: "", symbol: ""},
                    options: null, log: 0, loading: false, data: null
                },
                table: {
                    options: [],
                    loading: false,
                    defaultSort: {prop: "", order: "descending"},
                    header: [], type: "Cluster", total: 100, start: 1, length: 10,
                    sort: "", order: "descending", data: [],
                    form: {
                        pct1: 0, pct2: 0, p_val: 1, p_val_adj: 1,
                        avg_log_fc: 0, ident: "", idents: [],
                        gene: {value: "", ensembl: "", symbol: ""}
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>