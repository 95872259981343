
/*
format data to echart options of main page bar plot

:param data is the data from /api/stats
*/
export function StatsBar(data, name) {

    let legendData = Object.keys(data.Value);
    let yAxis = data.Cells;
    let series = [];

    legendData.forEach(function(element) {
        let tempData = [];

        yAxis.forEach(function(ele) {
           tempData.push(data.Value[element][ele]);
        });

        series.push({
            name: element,
            type: 'bar',
            stack: '总量',
            data: tempData
        });
    });

    return {
        toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
                mark: {show: true},
                dataView: {
                    show: true, readOnly: false,  title: "show data",
                    lang: ["Data view", "Close", "Refresh"]
                },
                magicType: {
                    show: true,
                    type: ['line', 'bar', 'stack', 'tiled'],
                    title: {
                        line: "switch to line plot",
                        bar: "switch to bar plot",
                        stack: "switch to stacked bar plot",
                        tiled: "switch tiled plot"
                    }
                },
                restore: {show: true, title: "restore"},
                saveAsImage: {show: true, title: "save png", type: "png", name: name}
            }
        },
        tooltip : {
            trigger: 'axis',
            axisPointer : {            // 坐标轴指示器，坐标轴触发有效
                type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
            }
        },
        legend: {
            data: legendData
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        yAxis:  {
            type: 'value'
        },
        xAxis: {
            type: 'category',
            data: yAxis
        },
        series: series
    }
}



/*
Make scatter plot
:param data is the data from /api/coord
 */
export function ScatterPlot(data) {

    let coord = "UMAP";
    if (data.coord.toLowerCase() === "tsne") {
        coord = "tSNE"
    }
    if (data.coord.toLowerCase() === "dm") {
        coord = "DC"
    }

    let ident1 = "Subtype";
    let ident2 = "Malignant";

    if (data.colorby.toLowerCase() == "subtype") {
        ident1 = "Cluster"
    }
    if (data.colorby.toLowerCase() == "malignant") {
        ident2 = "Cluster"
    }

    let series = [];

    for (let key in data.data) {
        series.push({
            name: key,
            type: 'scatter',
            symbolSize: 4,
            data: data.data[key]
        })
    }

    return {
        title: {
            text: `Cell: ${data.cell}; Disease: ${data.disease}`,
            left: 'center',
            top: 0
        },
        legend: {
            y: 'bottom',
            data: data.group.sort()
        },
        toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
                mark: {show: true},
                dataView: {
                    show: true, readOnly: false,  title: "show data",
                    lang: ["Data view", "Close", "Refresh"]
                },
                restore: {show: true, title: "restore"},
                saveAsImage: {show: true, title: "save png", type: "png", name: `${data.cell}_${data.disease}_${coord}`}
            }
        },
        tooltip : {
            trigger: 'axis',
            axisPointer : {            // 坐标轴指示器，坐标轴触发有效
                type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
            },
            formatter: function (obj) {
                let data = obj[0];

                return '<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                    + `<span style="color: ${data.color}">&#11044;</span>` +  data.data[2] + '</div>'
                    + `${coord}1: ` + data.data[0] + '<br>'
                    + `${coord}2: ` + data.data[1] + '<br>'
                    + `Cell ID: ` + data.data[3] + '<br>'
                    + `Patient: ` + data.data[4] + '<br>'
                    + `${ident1}: ` + data.data[5] + '<br>'
                    + `${ident2}: ` + data.data[6] + '<br>'
            }
        },
        xAxis: {
            type: 'value',
            name: `${coord}1`,
            position: "bottom",
            nameGap: 18,
            nameLocation: "center",
            nameTextStyle: {
                fontSize: 16
            }
        },
        yAxis: {
            type: 'value',
            name: `${coord}2`,
            nameGap: 15,
            nameLocation: "center",
            nameTextStyle: {
                fontSize: 16
            }
        },
        series: series
    }
}


/*
Make scatter plot of expression
 */
export function ScatterPlotExpr(data, log) {
    let data1 = JSON.parse(JSON.stringify(data));
    let maxVal = data1.max;
    let expr = data1.data;

    if (log === 2) {
        let newData = [];
        expr.forEach(function(row) {
            row[2] = Math.log2(row[2] + 1);
            newData.push(row);
        });
        expr = newData;
        maxVal = Math.log2(maxVal + 1);
    } else if (log === 10) {
        let newData = [];
        expr.forEach(function(row) {
            row[2] = Math.log10(row[2] + 1);
            newData.push(row);
        });
        expr = newData;
        maxVal = Math.log10(maxVal + 1);
    }

    let coord = "UMAP";
    if (data.coord === "tsne") {
        coord = "tSNE"
    }

    return {
        title: {
            text: `Expression of ${data.gene.Name} (${data.gene.Ensembl})`,
            left: 'center',
            top: 0
        },
        visualMap: {
            min: 0,
            max: maxVal,
            dimension: 2,
            orient: 'vertical',
            right: 10,
            top: 'center',
            text: ['HIGH', 'LOW'],
            calculable: true,
        },
        toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
                mark: {show: true},
                dataView: {
                    show: true, readOnly: false,  title: "show data",
                    lang: ["Data view", "Close", "Refresh"]
                },
                restore: {show: true, title: "restore"},
                saveAsImage: {show: true, title: "save png", type: "png", name: `${data.cell}_${data.disease}_${data.gene.Ensembl}_${data.gene.Name}_${coord}${log === 0 ? "" : "_log"+log}`}
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer : {            // 坐标轴指示器，坐标轴触发有效
                type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
            },
            formatter: function (obj) {
                let data = obj[0];

                return '<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                    + `<span style="color: ${data.color}">&#11044;</span>` +  data.data[3] + '</div>'
                    + `${coord}1: ` + data.data[0] + '<br>'
                    + `${coord}2: ` + data.data[1] + '<br>'
                    + `Counts: ` + data.data[2] + '<br>'
                    + `Sample: ` + data.data[4] + '<br>'
                    + `Patient: ` + data.data[5] + '<br>'
            }
        },
        xAxis: {
            type: 'value',
            name: `${coord}1`,
            position: "bottom",
            nameGap: 18,
            nameLocation: "center",
            nameTextStyle: {
                fontSize: 16
            }
        },
        yAxis: {
            type: 'value',
            name: `${coord}2`,
            nameGap: 15,
            nameLocation: "center",
            nameTextStyle: {
                fontSize: 16
            }
        },
        series: [{
            name: 'Counts',
            type: 'scatter',
            symbolSize: 4,
            data: expr
        }]
    };
}
