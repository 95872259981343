<template>
    <div>

        <h3>{{ page.toUpperCase() }}</h3>
        <el-divider />
        <div v-html="data" id="area"></div>
    </div>
</template>

<script>
    import urls from '../utils/const'

    import showdown from 'showdown'

    export default {
        name: "Docs",
        data() {
            return {
                page: "",
                data: [],
                converter: new showdown.Converter(),
            }
        },
        methods: {
            init() {
                this.page = this.$route.params.id;
                const self = this;
                
                this.axios.get(`${urls.static}/${this.page}.md`).then(response => {
                    self.data = self.converter.makeHtml(response.data)
                }).catch(error => {
                    window.console.log(error);
                    self.$notify({
                        showClose: true,
                        type: 'error',
                        title: `Error Status: ${error.response.status}`,
                        message: error.response.data["Message"]
                    });
                })
            }
        },
        mounted() {this.init()},
        watch: {  // watch changes of id
            '$route.params.id': function () {this.init()}
        }
    }
</script>

<style>
    #area {
        background-color: #F2F6FC
    }

    strong {
        color: #F56C6C;
    }

    li {
        margin-left: 10px;
    }

    li li {
        margin-left: 20px;
    }

    ul {list-style-position: inside;}

    code {
        background-color: #E4E7ED;
    }
</style>