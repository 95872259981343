
export function createURL(...urls) {
    let res = [];

    let temp = document.getElementById("Prefix").value.replace(/(^\/|\/$)/g, "");
    if (temp !== "") {
        res.push(temp)
    }

    for (let i=0; i < urls.length; i++) {
        let temp = urls[i].toString();
        temp = temp.replace(/(^\/|\/$)/g, "");

        if (temp !== "") {
            res.push(temp)
        }
    }
    return "/" + res.join("/")
    // return "http://chenlinlab.synology.me:4999/" + res.join("/")
}

export function fullURL(...urls) {
    let domain = document.getElementById("Domain").value.replace(/(^\/|\/$)/g, "");
    return domain + "/" + createURL(...urls)
}


export function isMobile() {
    return navigator.userAgent.match(/(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
}


export function sampleData() {
    let data = [];

    for (let i = 0; i <= 360; i++) {
        let t = i / 180 * Math.PI;
        let r = Math.sin(2 * t) * Math.cos(2 * t);
        data.push([r, i])
    }

    return {
        title: {
            text: '极坐标双数值轴'
        },
        legend: {
            data: ['line']
        },
        polar: {
            center: ['50%', '54%']
        },
        tooltip: {
            trigger: 'axis',
                axisPointer: {
                type: 'cross'
            }
        },
        angleAxis: {
            type: 'value',
                startAngle: 0
        },
        radiusAxis: {
            min: 0
        },
        series: [
            {
                coordinateSystem: 'polar',
                name: 'line',
                type: 'line',
                showSymbol: false,
                data: data
            }
        ],
        animationDuration: 2000
    }
}
